import BVStyleguide from '@bv/styleguide';
import { CheckCircle2 } from 'lucide-react';
import { motion } from 'framer-motion';
import { ofertasIconAsset } from '@review/assets';
import { BrandsCarousel } from '@review/components/BrandsCarousel';
import * as singleSpa from 'single-spa';

export default function Success() {
  return (
    <div className="flex h-[calc(100vh-69px)] flex-col">
      <div className="container flex flex-col items-center py-6 text-center">
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ type: 'spring', stiffness: 400, damping: 20, delay: 0.5 }}
          className="mb-2">
          <CheckCircle2 size={64} className="text-success-dark" />
        </motion.div>
        <motion.h4 initial={{ opacity: 0, y: -16 }} animate={{ opacity: 1, y: 0 }} className="text-success-dark text-bold font-ubuntu text-xl">
          Obrigado pela sua avaliação!
        </motion.h4>
        <motion.p
          transition={{ delay: 0.2 }}
          initial={{ opacity: 0, y: -16 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-typography-secondary text-sm">
          Seu comentário foi enviado e passara por uma análise antes de ser publicado.
        </motion.p>
      </div>

      <div className="flex-1 bg-gradient-to-b from-[#FFFFFF] to-[#ACCAFF] py-6">
        <div className="container mt-10 flex flex-col items-center text-center">
          <img src={ofertasIconAsset} alt="Cesta de ofertas" className="mb-2 h-[100px] w-[100px]" />
          <h4 className="text-xl">Aproveite mais descontos exclusivos!</h4>
          <p className="text-typography-secondary text-sm">
            Que tal usar outro desconto para presentear alguém especial? Volte para a nossa área de óticas e encontre a oferta perfeita!
          </p>

          <BVStyleguide.Button onClick={() => singleSpa.navigateToUrl('/login')}>Ver ofertas agora</BVStyleguide.Button>
        </div>
        <BrandsCarousel />
      </div>
    </div>
  );
}
