import { api } from './api';

export interface Review {
  id: string;
  ratings: Rating[];
  avgRating: number;
  comment: string;
  userId: string;
  companyId: string;
  couponId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  approved: boolean;
  approvedBy: string;
  userName: string;
}

export interface Rating {
  aspect: 'place' | 'people' | 'product';
  stars: number;
}

export type CreateReviewDto = {
  ratings: Rating[];
  couponId: string;
  comment?: string;
};

function createReviewService(body: CreateReviewDto): Promise<{ message: string }> {
  return api.post(`/v2/reviews`, body);
}

export { createReviewService };
