import { EmblaOptionsType } from 'embla-carousel';
import AutoScroll from 'embla-carousel-auto-scroll';
import useEmblaCarousel from 'embla-carousel-react';
import { useCallback, useEffect, useState } from 'react';
import {
  calvinKleinAsset,
  columbiaAsset,
  converseAsset,
  cutlerAndCrossAsset,
  dknyAsset,
  donnaKaraAsset,
  dragonAsset,
  ferragamoAsset,
  flexonAsset,
  karlLagerfeldAsset,
  lacosteAsset,
  longchampAsset,
  lanvinAsset,
  liuJoAsset,
  marchonAsset,
  nauticaAsset,
  nikeVisionAsset,
  paulSmithAsset,
  pilgrimAsset,
  pureAsset,
  salvatoreFerragamoAsset,
  shinolaAsset,
  skagaAsset,
  victoriaBeckhamAsset,
  zeissAsset,
} from '../../assets';

const CAROUSEL_OPTIONS: EmblaOptionsType = { loop: true, dragFree: false, slidesToScroll: 1 };
const SLIDES = [
  calvinKleinAsset,
  columbiaAsset,
  donnaKaraAsset,
  cutlerAndCrossAsset,
  dknyAsset,
  converseAsset,
  dragonAsset,
  skagaAsset,
  flexonAsset,
  karlLagerfeldAsset,
  lacosteAsset,
  lanvinAsset,
  longchampAsset,
  liuJoAsset,
  marchonAsset,
  nauticaAsset,
  nikeVisionAsset,
  paulSmithAsset,
  pilgrimAsset,
  pureAsset,
  salvatoreFerragamoAsset,
  ferragamoAsset,
  shinolaAsset,
  victoriaBeckhamAsset,
  zeissAsset,
];

export function BrandsCarousel() {
  const [emblaRef, emblaApi] = useEmblaCarousel(CAROUSEL_OPTIONS, [AutoScroll({ playOnInit: true, speed: 0.7 })]);

  return (
    <div className="relative mt-4">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container relative">
          {SLIDES.map((image, index) => (
            <div className="embla__slide flex max-h-[150px] max-w-[202px] flex-[0_0_50%] items-center justify-center sm:flex-[0_0_25%]" key={index}>
              <img src={image} className="mx-auto aspect-[2/1] object-contain" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
