import BVStyleguide from '@bv/styleguide';
import { beneficioVisaoLogoAsset } from '../../assets';
import { motion } from 'framer-motion';
import { Form } from './components/Form';
import { useSearchParams } from 'react-router-dom';

export default function Rating() {
  const [searchParams] = useSearchParams();

  const opticsName = searchParams.get('otica');

  return (
    <motion.div className="container" initial={{ opacity: 0, y: 16 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
      <div className="flex-1 space-y-4">
        <h3 className={BVStyleguide.cn('text-typography-body font-sans text-2xl font-semibold')}>Como foi sua experiência?</h3>
        <p className="text-typography-secondary font-sans leading-[150%]">
          A sua opinião é fundamental para que possamos manter e aprimorar a qualidade do atendimento e produtos nas óticas parceiras. Compartilhe
          conosco o que achou da sua visita na {opticsName} e ajude-nos a melhorar nossas experiência com nossos colaboradores.
        </p>
      </div>

      <Form />
    </motion.div>
  );
}
